import React, { useState, useEffect } from 'react';
import NavBar from '../components/Navbar/NavBar';
import Footer from '../components/Footer';
import chemicalsByCategory from './chemicaldata';
import heroBackground from '../images/fashio.webp';
import './product.css';

const Products = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    const [selectedCategory, setSelectedCategory] = useState("Pre-Treatment");
    const [expandedChemicalName, setExpandedChemicalName] = useState(null); // Track the name of the expanded chemical

    const handleCategoryClick = (category) => {
        setSelectedCategory(category);
        setExpandedChemicalName(null); // Reset expanded chemical when category changes
    };

    const toggleChemicalDetails = (chemicalName) => {
        setExpandedChemicalName(expandedChemicalName === chemicalName ? null : chemicalName); // Toggle details
    };

    return (
        <div className="products-page">
            <NavBar />

            <div className="content-area">
                {/* Hero Section */}
                <section className="hero-section">
                    <div className="hero-overlay">
                        <div className="container">
                            <h1>Enhance Your Textile Production</h1>
                            <p>Precision chemistry for modern textile manufacturing.</p>
                            <button className="consultation-button">Request a Consultation</button>
                        </div>
                    </div>
                </section>

                {/* Categories Section */}
                <section className="categories-section">
                    <div className="container">
                        <h2 className="section-title">Textile & Dyes Auxiliaries</h2>
                        <p className="section-description">
                            Textile auxiliaries are chemical formulations used for preparing fabric for dyeing. The dyeing auxiliaries further make the process smoother and help in achieving the desired outcomes.
                        </p>
                        <div className="categories-buttons">
                            {Object.keys(chemicalsByCategory).map((category) => (
                                <button
                                    key={category}
                                    onClick={() => handleCategoryClick(category)}
                                    className={`category-button ${selectedCategory === category ? 'active' : ''}`}
                                >
                                    {category}
                                </button>
                            ))}
                        </div>
                    </div>
                </section>

                {/* Main Content Section */}
                <section className="main-content-section">
                    <div className="container">
                        <div className="chemicals-grid">
                            {chemicalsByCategory[selectedCategory].map((chemical, index) => (
                                <div
                                    key={index}
                                    className={`chemical-tile ${expandedChemicalName === chemical.name ? 'expanded' : ''}`}
                                >
                                    <div
                                        className="chemical-header"
                                        onClick={() => toggleChemicalDetails(chemical.name)}
                                    >
                                        <img src={chemical.image} alt={chemical.name} className="chemical-image" />
                                        <h3>{chemical.name}</h3>
                                        <button className="expand-button">
                                            {expandedChemicalName === chemical.name ? '-' : '+'}
                                        </button>
                                    </div>

                                    {/* Details Section */}
                                    {expandedChemicalName === chemical.name && (
                                        <div className="chemical-details">
                                            <h4>Description</h4>
                                            <p>{chemical.description}</p>
                                            <h4>Benefits</h4>
                                            <ul>
                                                {chemical.benefits.map((benefit, i) => (
                                                    <li key={i}>{benefit}</li>
                                                ))}
                                            </ul>
                                            <h4>Expert Tip</h4>
                                            <p>{chemical.expertTip}</p>

                                            {/* Quote Section */}
                                            {chemical.getQuoteDetails && (
                                                <div className="quote-section">
                                                    <h4 className="quote-title">Get a Quote</h4>
                                                    <ul className="quote-details-list">
                                                        <li><strong>Minimum Order Quantity:</strong> {chemical.getQuoteDetails.minimumOrderQuantity}</li>
                                                        <li><strong>Packaging Type:</strong> {chemical.getQuoteDetails.packagingType}</li>
                                                        <li><strong>Usage/Application:</strong> {chemical.getQuoteDetails.usage}</li>
                                                        <li><strong>Shelf Life:</strong> {chemical.getQuoteDetails.shelfLife}</li>
                                                        <li><strong>Form:</strong> {chemical.getQuoteDetails.form}</li>
                                                        <li><strong>Storage Instructions:</strong> {chemical.getQuoteDetails.storageInstructions}</li>
                                                    </ul>
                                                    <button className="request-quote-button">Request a Quote</button>
                                                </div>
                                            )}

                                            <a href={chemical.brochure} download className="download-link">
                                                Download Brochure
                                            </a>
                                        </div>
                                    )}
                                </div>
                            ))}
                        </div>
                    </div>
                </section>
            </div>

            <Footer />
        </div>
    );
};

export default Products;
