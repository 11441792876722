import React, { useState } from 'react';
import {
  BrowserRouter as Router,
  Routes,
  Route
} from 'react-router-dom';
import Home from './pages/Home';
import Contact from './pages/Contact';
import DemoProduct from './pages/DemoProduct';
import { WhatsAppWidget } from 'react-whatsapp-widget';
import Products from './pages/Products';
import 'react-whatsapp-widget/dist/index.css';
import { useDocTitle } from './components/CustomHook';
import ScrollToTop from './components/ScrollToTop';

function App() {
  useDocTitle("Texcellence Specialities Pvt LTD");

  const [isBouncing, setIsBouncing] = useState(true);

  const handleClick = () => {
    setIsBouncing(false);
  };

  const widgetStyle = {
    position: 'fixed',
    bottom: '30px',
    right: '20px',
    zIndex: 1000,
    backgroundColor: '#25D366',
    padding: '10px',
    borderRadius: '50%',
    boxShadow: '0 4px 16px rgba(0, 0, 0, 0.2)',
    animation: isBouncing ? 'bounce 2s infinite' : 'none',
  };
  
  // Add smaller size for mobile views using media queries
  const mobileWidgetStyle = `
    @media (max-width: 768px) {
      .whatsapp-widget {
        transform: scale(1); /* Adjust size for mobile */
        bottom: 15px; /* Bring closer to the bottom */
        right: 15px; /* Adjust positioning */
      }
    }
  `;
  

  return (
    <>
      <Router>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/get-demo" element={<DemoProduct />} /> 
            <Route path="/products" element={<Products/>}/>
          </Routes>
        </ScrollToTop>

      </Router>
      <div style={widgetStyle} onClick={handleClick}>
        <WhatsAppWidget 
          phoneNumber="981921689"
          companyName="Texcellence Specialities Pvt LTD"
          message="Hi there! 👋 How can we help you?"
        />
      </div>
      <style>
        {`
          @keyframes bounce {
            0%, 20%, 50%, 80%, 100% {
              transform: translateY(0);
            }
            40% {
              transform: translateY(-15px);
            }
            60% {
              transform: translateY(-10px);
            }
          }
        `}
      </style>
    </>
  );
}

export default App;
